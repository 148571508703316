.upload-container {
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 33px;
    cursor: pointer;
}

.upload-action-input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-preview {
    height: 100%;
    width: 100%;
}

.preview-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
