@import '../../configs/variables';

$height: 60px;
.header {
    background-color: $app-main-color;
    height: $height;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 999;
}

.logo-container {
    position: absolute;
    height: $height;
    width: 250px;
    text-align: center;
    background-color: white;
    border-radius: 0 30px 30px 0;
}

.logo {
    height: 40px;
    margin-top: 10px;
}

.logout-box {
    position: absolute;
    right: 0;
    top: 0;
    height: $height;
}
